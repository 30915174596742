import React from "react"
import { Helmet } from "react-helmet"
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client"

import MainLayout from "../../Layouts/layoutLanding"
import MobileBooking from "./MobileBooking"

const MobileCheckout = () => {
  const client = new ApolloClient({
    uri: process.env.GATSBY_API_URL,
    cache: new InMemoryCache(),
  })

  return (
    <ApolloProvider client={client}>
      <Helmet>
        {
          <script>
            {`!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window,document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
           fbq('init', '860210341343925'); 
          fbq('track', 'PageView');
          `}
          </script>
        }
        {
          <noscript>
            {`
              <img
              height="1"
              width="1"
              src="https://www.facebook.com/tr?id=860210341343925&ev=PageView
          &noscript=1"
            />`}
          </noscript>
        }
        {
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-56517066-2"
          ></script>
        }
        {
          <script>
            {`window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
  
    gtag('config', 'UA-56517066-2');
  `}
          </script>
        }
      </Helmet>
      <MobileBooking />
    </ApolloProvider>
  )
}

export default MobileCheckout
